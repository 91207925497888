import AWS from 'aws-sdk';
import {DetectDocumentTextCommand, TextractClient} from '@aws-sdk/client-textract';

let textractClient;

const TextExtractionUtils = function (region, cognitoIdentityPoolId) {
    AWS.config.region = region;
    AWS.config.credentials = new AWS.CognitoIdentityCredentials({
        IdentityPoolId: cognitoIdentityPoolId
    });
    AWS.config.credentials.get(function(){
        textractClient = new TextractClient({
            region: region,
            credentials: {
                accessKeyId: AWS.config.credentials.accessKeyId,
                secretAccessKey: AWS.config.credentials.secretAccessKey,
                sessionToken: AWS.config.credentials.sessionToken
            }
        });
    });
};

TextExtractionUtils.prototype.detectTextFromDocument = async function (image) {
    const byteArray = new Buffer(image.replace(/^[\w\d;:\/]+base64\,/g, ''), 'base64');
    const detectDocumentTextCommand = new DetectDocumentTextCommand({Document: {Bytes: byteArray}});
    const imageAnalysedData = await textractClient.send(detectDocumentTextCommand);
    console.log('Response from Textract ' + JSON.stringify(imageAnalysedData));
    return imageAnalysedData;
}

TextExtractionUtils.prototype.extractCoordinatesForBlock = function (block, imgRef) {
        const coordinates = {xStart: 0, yStart: 0, width: imgRef.naturalWidth, height: imgRef.naturalHeight};
        try {
            console.log("Getting coordinates from block " + JSON.stringify(block));
            const xStart = block.Geometry.BoundingBox.Left * imgRef.naturalWidth;
            const yStart = block.Geometry.BoundingBox.Top * imgRef.naturalHeight;
            const width = block.Geometry.BoundingBox.Width * imgRef.naturalWidth;
            const height = block.Geometry.BoundingBox.Height * imgRef.naturalHeight;
            coordinates.xStart = Math.min(xStart, imgRef.naturalWidth);
            coordinates.yStart = Math.min(yStart, imgRef.naturalHeight);
            coordinates.width = Math.min(width, imgRef.naturalWidth);
            coordinates.height = Math.min(height, imgRef.naturalHeight);
        } catch (e) {
            console.log("Exception while getting block coordinates " + JSON.stringify(e));
            console.log("Block " + JSON.stringify(block));
        }
        return coordinates;
}

export default TextExtractionUtils;