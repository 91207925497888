import {ColorExtractionUtils} from "../index";

const SALUtils = function () {
};

SALUtils.prototype.extractSalColorFromImage = function (imgRef, blockCoordinates, colorCount=2, quality=1) {
    if (!imgRef) {
        console.log("Invalid image passed");
        return null;
    }
    if (!blockCoordinates?.width || !blockCoordinates?.height || !blockCoordinates?.xStart || !blockCoordinates?.yStart) {
        console.log("Invalid coordinates passed " + JSON.stringify(blockCoordinates));
        return null;
    }
    const imagePalette = new ColorExtractionUtils().getImagePalette(imgRef, colorCount, quality, true, blockCoordinates);
    console.log("Extracted palette from image: " + JSON.stringify(imagePalette));
    const dominantColor = imagePalette[0];
    return this.getHexValueForRgbSalColor({red: dominantColor[0], green: dominantColor[1], blue: dominantColor[2]})
}

SALUtils.prototype.getHexValueForRgbSalColor = function (rgbColor) {
    if (!rgbColor?.red || !rgbColor?.blue || !rgbColor?.green) {
        console.log("Invalid RGB values provided " + JSON.stringify(rgbColor));
        return null;
    }

    if (isYellow(rgbColor)) {
        return '#FEE966';
    } else if (isBlue(rgbColor)) {
        return '#9DDAF1';
    } else if (isGreen(rgbColor)) {
        return '#C1E766';
    } else if (isPink(rgbColor)) {
        return '#FDC3D1'
    } else {
        return null;
    }
}

const isYellow = (color) => {
    return color.red > color.green && color.green > color.blue;
}
const isBlue = (color) => {
    return color.red < color.green && (color.green < color.blue || Math.abs(color.green - color.blue) < 10);
}
const isGreen = (color) => {
    return color.red < color.green && color.green > color.blue && (color.red > color.blue || (color.blue - color.red) < 10);
}
const isPink = (color) => {
    return color.red > color.blue && color.blue > color.green;
}

export default SALUtils;